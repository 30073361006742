@import "~bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Poppins-regular;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-bold;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-light;
  src: url("./assets/fonts/Poppins-Light.ttf");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins-regular !important;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.cursor-pointer {
  cursor: pointer;
}

//for disabled select in whole web portal
body {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

// /* Importing Datepicker SCSS file. */
// @import "~ngx-bootstrap/datepicker/bs-datepicker";
.custom-btn {
  padding: 7px 15px;
  color: #fff;
  background-color: #03a7e6;
  outline: none;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
}

.sidenav {
  mat-expansion-panel-header {
    color: #fff !important;
    height: fit-content !important;
    padding: 0 !important;
  }

  mat-panel-description {
    color: #fff !important;
    padding: 0 !important;
  }

  mat-expansion-panel {
    background: transparent !important;
    border: none !important;
    outline: none !important;
    color: #fff !important;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
    padding: 0 0 0 45px !important;

    p {
      margin: 10px 0;
    }
  }
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

.custom-btn {
  background: linear-gradient(90deg,
      #ca0a0a 0,
      #970808 50%,
      #ca0a0a) !important;
  outline: none;
  border: none;
  border-radius: 7px;
  color: #fff;
  padding: 7px 15px;
}

// scrollbar changes in whole site
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-modal-popup {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

// custom checkbox css
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: #ca0a0a !important;
  color: #fff !important;
}

.mat-checkbox-label {
  margin: 0;
  font: 14px/16px Poppins-regular !important;
}

//custom radio button

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: #ca0a0a !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ca0a0a !important;
}

.mat-checkbox-inner-container {
  background: #fff !important;
}

.mat-checkbox {
  label {
    margin: 0 !important;
  }
}

//custom textbox css
.custom-textbox,
.custom-text-area {
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  padding: 7px 15px;
  font-size: 13px;
  font-family: Poppins-regular;

  &:focus {
    border: 1px solid #03a7e6;
  }
}

//nz select module css
nz-select {
  width: 100%;
  text-align: left !important;
  font-size: 13px;
  font-family: Poppins-regular;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100% !important;
  height: 34px !important;
  display: flex;
  align-items: center;
  font-size: 13px !important;
  font-family: Poppins-regular !important;
}

//red border
.red-border {
  border: 1px solid #ca0a0a;
}

.ant-table-thead>tr>th {
  background-color: #ab0909 !important;
  color: white;
  font-weight: 600;

  &:hover {
    background-color: #ff2525 !important;
  }
}

.ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 25px !important;
  font-weight: 400 !important;

  .ant-modal-confirm-content {
    font-size: 12px !important;
  }

  .ant-modal-confirm-title {
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .ant-btn {
    background-color: #ab0909 !important;
    border: none !important;
    outline: none !important;
    color: #fff !important;
  }
}

.no-wrap {
  white-space: nowrap;
}

.ant-table-tbody {
  padding: 10px !important;
}

.ant-table-thead>tr>th,
td {
  padding: 10px !important;
}

.invoice-popup {
  max-height: 95vh;
  height: auto;

  .mat-dialog-container {
    padding: 0px !important;
  }
}

.ant-btn-primary {
  background-color: #ab0909 !important;
  border-color: #ab0909 !important;
}

@media (max-width: 1024px) {
  .invoice-popup {
    width: 40% !important;
  }
}

.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: url("./assets/images/bg.jpeg");
}

.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #a90909 !important;
  cursor: pointer;
  transition: all 0.2s;
}

.ant-layout-sider {
  position: relative;
  min-width: 0;
  background: url("./assets/images/bg.jpeg");
  transition: all 0.2s;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #970808 !important;
}

.kot-item-modal {
  .ant-modal-body {
    padding: 0%;
  }
}

.ant-dropdown-menu {
  min-width: 100px;
  max-height: 50%;
  overflow: auto;
  // z-index: 99999999999999;
}

.cdk-overlay-connected-position-bounding-box {
  // position: relative !important;
}

.ant-menu-vertical-left.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical-right.ant-menu-sub:not([class*="-active"]),
.ant-menu-vertical.ant-menu-sub:not([class*="-active"]) {
  padding-bottom: 15px !important;
}

.mat-dialog-container {
  height: auto !important;
}

.ant-avatar {
  padding: 0;
  display: inline-block;
  color: #a80013;
  text-align: center;
  background-color: #ffe7e6;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  cursor: pointer;
}

.anticon {
  color: inherit;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: 0px !important;
  margin-right: 0px;
}

.ant-tabs-nav-wrap {
  padding-left: 15px;
}

.ant-tabs-ink-bar {
  color: #a80013 !important;
  background: #a80013 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #a80013 !important;

  &:hover {
    color: #a80013 !important;
  }
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #a80013 !important;
}


.sales-report {
  .ant-table-thead th:nth-child(5) {
    width: 87px !important;
  }
  .ant-table-tbody td:nth-child(2) {
    cursor: pointer !important;
  }

}

.ant-table {
  font-size: 12px !important;
}

.ant-dropdown {
  overflow: auto !important;
}

.ant-layout-content{
  // height: 100vh !important;
}
